.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.record-page-container {
  display: flex;
  flex-direction: column;
}

.prompt-and-button-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  background-color: whitesmoke;
  height: 500px;
  width: 700px;
}
.prompt-text {
  display: flex;
  box-sizing: content-box;
  width: 90%;
  height: 90%;
}
.prompt-button {
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-around;
}
.button-container {
  padding-top: 4em;
  width: 70%;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}
.image {
  width: 70%;
}
